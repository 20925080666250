<template>
   <div class="flex h-full">
        <div class="side-panel flex-shrink-0 description-container">
            <ReferralSourcesDescription ></ReferralSourcesDescription>
        </div>
        <div class="flex flex-grow content">
            <ReferralSourcesList></ReferralSourcesList>
        </div>
    </div>
</template>


<script>
import ReferralSourcesList from "@/components/guest_cards/settings/ReferralSources/ReferralSourcesList";
import ReferralSourcesDescription from "@/components/guest_cards/settings/ReferralSources/ReferralSourcesDescription";
export default {
    name:'ReferralSourcesManagement',
    components:{ReferralSourcesList,ReferralSourcesDescription},
    data(){
        return{
            LeadSources:[],
        }
    },
}
</script>
<style scoped>
.content {
    @apply flex-col overflow-auto;
  }
.content > div {
    @apply w-full;
  }
.description-container{
   background: #a7ccee ;
   border: #519ee0 2px solid;
   color: black;
 }
</style>